import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
import config from '/package.json'
import enums from "@/utils/enums";


export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/e'
    },
    {
      path: '/e/:id',
      component: resolve => require(['@/views/evaluate'], resolve),
      meta:{ title: '客户评价-' + config.version }
    },
    {
      path: '/b',
      component: resolve => require(['@/views/board'], resolve),
      meta:{ title: '概要看板' }
    },
    {
      path: '/q/:id/:type?/:token?',
      component: resolve => require(['@/views/research/Research'], resolve),
      meta:{ title: '客户评价' }
    },
    {
      path: '/r/:id/:type?/:token?',
      component: resolve => require(['@/views/research/Research'], resolve),
      meta:{ title: '客户评价',type:enums.FEEDBACK_TYPE.SEND_REPAIR_SERVICE.v }
    },
    {
      path: '/n/:id/:type?/:token?',
      component: resolve => require(['@/views/research/Research'], resolve),
      meta:{ title: '客户评价',type:enums.FEEDBACK_TYPE.NEW_WORK_ORDER.v }
    },
    {
      path: '/p/:id([1-9]\\d*)',
      component: resolve => require(['@/views/InsideProduceBill/List.vue'], resolve),
      meta:{ }
    },
    {
      // type 0 为分享 1 为 签字
      path: '/sign/:id([1-9]\\d*)/:type([0-2]\\d*)',
      component: resolve => require(['@/views/RemoteSign/SignView.vue'], resolve),
      meta:{ title: "签字",}
    },
    {
      path: '/pay/:id/',
      component: resolve => require(['@/views/PayMent/PayView.vue'], resolve),
      meta:{ title: '客户付款' }
    },
    {
      path: '/404',
      name:'404',
      component: resolve => require(['@/views/NotFound'], resolve),
      meta:{ title: '404 NotFound' }
    }
  ]
})
