export default {
    COLUMNS_WIDTH: {
        TIME: 170,
        TIME_POINT: 100,
        USER: 100, // 联系人
        USERS: 150, // 带标签的联系人
        UNIT: 60,
        WORK_UNIT: 90,
        WARE: 100,
        MOBILE: 120, // 电话
        DATE: 120,
        BILL_CODE: 150,
        CODE_CODE: 160,
        PURCHASE_CODE: 170,
        SWITCH: 100,
        CUSTOMER_NAME: 200, // 客户
        GOODS_NAME: 160, // 商品名
        CUSTOMER_CODE: 100, // 客户编号
        GOODS_PICTURE: 200, // 商品图片
        GOODS_TYPE: 150, // 商品类型
        SERVE_TYPE: 150, // 服务方式
        GOODS_STANDARD: 200, // 商品编号、商品规格
        GOODS_NUMBER: 130,
        PRODUCT_NUMBER: 120,
        INDEX: 30,
        ID: 90,
        SORT: 60,
        NUM: 110,
        GOODS_SOURCE: 100,
        REMARK: 200, // 跟单内容
        REMARK_MAX: 300,
        TAG: 100, // 标签
        TAG_MAX: 120,
        ADDRESS: 240, // 地址
        ATTACHMENT_TABLE: 100,
        DEPARTMENT_NAME: 100,
        PROGRESS: 220,
        CASCADER: 100, // 类别
        PRODUCTION_LINE: 100,
        PRODUCTION_LINE_EDIT: 146,
        NAME: 140,
        OPERATION: 70,
        PATTERN: 120,
        PRIORITY: 120, // 优先级
        PROCESS_STATUS: 120,
        CONTRACT_NUMBER: 150,
        WORK_NAME: 160,
        WORK_ORDER_NUMBER: 160,
        WORK_ORDER_NAME: 180,
        CRAFT_NAME: 150, // 工序名
        CRAFT_CODE: 100,
        PRODUCE_CODE: 160,
        PRODUCE_QR_CODE: 130,
        WORKSHOP: 100,
        WORKSHOP_EDIT: 146,
        NEED_QUALITY: 80,
        REPORT_NUM: 100,
        INSPECTION_CODE: 100,
        WASTE_ITEM_CODE: 260,
        WASTE_ITEM_NAME: 260,
        WASTE_ITEM_LIST: 160,
        CRAFT_NAMES: 300,
        SPAREPART: 160,
        SERIAL_NUMBER: 120,
        PRICE: 120,
        TOTAL_MONEY: 120,
        SPAREgoods: 300,
        STATION_SPACE: 120,
        STATION_SPACE_ULine: 160,
        STATION_WORKER_SPACE: 200,
        STATION_WORKER_HEAD: 260,
        IMAGE: 160,
        WORKER_OEDER_TYPE: 150,
        MAINRTENANCE: 200, // 维保设备车间、产线
        STATUS: 100,
        PLAN_TIME: 200, // 计划周期
        WORK_SHEET_PROGRESS: 350, // 加工单工序进度
        FIELD_TYPE: 90, // 扩展字段类型,
        RATIO: 300
    },
    STATE: {
        FEEDBACK_REPORT_FAULT_MOBILE: {
            v: '006'
        //    系统提示异常
        }
    },
    IMG: {
        ABBREVIATION: '?imageView2/2/w/32/h/32',
        ABBREVIATIONFORBANNER: '?imageView2/2/w/256/h/256'
    },
    BOOL: {
        YES: {
            v: 1,
            is: true,
            name: '是',
            arrearsName: '有欠款',
            enableName: '禁用',
            enableColor: 'red',
            buttonType: 'primary',
            buttonName: '启用',
            readName: '已读',
            plan: '有',
            stagColor: '2',
            settlement: '已结算'
        },
        NO: {
            v: 2,
            is: false,
            name: '否',
            arrearsName: '无欠款',
            enableName: '启用',
            enableColor: 'blue',
            buttonType: 'danger',
            buttonName: '禁用',
            readName: '未读',
            plan: '无',
            stagColor: '1',
            settlement: '待结算'
        }
    },
    // 调研模板分制枚举
    RESEARCH_GRADE: {
        FIVE_GRADE: {
            v: 1,
            name: '五分值'
        },
        TEN_GRADE: {
            v: 2,
            name: '十分值'
        }
    },
    // 调研模板题型
    RESEARCH_TYPE: {
        SCORE: {
            v: 1,
            name: '评分'
        },
        SELECTOR: {
            v: 2,
            name: '单选'
        },
        MULTI_SELECTOR: {
            v: 3,
            name: '多选'
        },
        Q_AND_A: {
            v: 4,
            name: '问答'
        },
        CSAT: {
            v: 5,
            name: 'CSAT',
            TEXT: '客户满意度'
        },
        NPS: {
            v: 6,
            name: 'NPS',
            TEXT: '净推荐值'
        }
    },
    // 调研满意度
    RESEARCH_HAPPY: {
        VERY_SATISFIED: {
            v: 1,
            name: '非常满意'
        },
        SATISFIED: {
            v: 2,
            name: '满意'
        },
        GENERAL: {
            v: 3,
            name: '一般'
        },
        DISSATISFIED: {
            v: 4,
            name: '不满意'
        },
        VERY_DISSATISFIED: {
            v: 5,
            name: '非常不满意'
        }
    },
    // 回访类型
    RETURNVISITTYPE:{
        PREVIEW:{
            v:1,
            name:"预览",
            msg:'当前为预览界面，回答题目将不做统计'
        },
        VIEW:{
            v:2,
            name:'查看'
        },
        EVALUATION:{
            v:3,
            name:'人工回访'
        },
        MANUAL_SURVEY:{
            v:4,
            name:'回访备注'
        }
    },
    FEEDBACK_TYPE: {
        STANDARD_SERVICE: {
            v: 1,
            name: '标准反馈'
        },
        SEND_REPAIR_SERVICE: {
            v: 2,
            name: '寄修反馈'
        },
        NEW_WORK_ORDER:{
            v:3,
            name:'新工单反馈'
        }
    },
    //标签颜色
    S_TAG: {
        BLUE: {
            v: '1',
            name: 'blue',
            color: '#00A578'
        },
        RED: {
            v: '2',
            name: 'red',
            color: '#f5222d'
        },
        ORANGE: {
            v: '3',
            name: 'orange',
            color: '#fa8c16'
        },
        GRAY: {
            v: '4',
            name: 'gray',
            color: '#757575'
        },
        GREEN: {
            v: '5',
            name: 'green',
            color: '#52c41a'
        },
        YELLOW: {
            v: '6',
            name: 'yellow',
            color: '#b1b116'
        },
        CYAN: {
            v: '7',
            name: 'cyan',
            color: '#13c2c2'
        },
        PINK: {
            v: '8',
            name: 'pink',
            color: '#eb2f96'
        }
    },
    getItem (enums, v) {
        return Object.values(enums).find(item => item.v === v)
    },
    getField (enums, v, fieldName) {
        return this.getItem(enums, v)[`${fieldName}`]
    },
    getName (enums, v) {
        return this.getField(enums, v, 'name')
    }
}
