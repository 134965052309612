<template>
  <div id="app">
    <a-locale-provider :locale="locale">
      <router-view/>
    </a-locale-provider>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  name: 'App',
  data(){
    return {
      locale: zhCN,
    }
  }
}
</script>
<style lang="less">
body{
  background-color: #f0f2f5 !important;
  color: #000;
}
.top_container{
  background-color: #3263f2;
  color: #fff;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
  .back_btn {
    position: fixed;
    top: 14px;
    left: 20px;
    font-size: 22px;
  }
}
.bottom_container{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  background-color: #fff;
  font-size: 16px;
  border-top: 1px solid #e6e6e6;
  div{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 4px;
    i{
      font-size: 24px;
      margin-bottom: 4px;
      color: #3263f2;
    }
    span{
      color: #222;
    }
  }
  div:nth-child(2){
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
  }
  .current{
    background-color: #3263f2;
    i{
      color: #fff;
    }
    span{
      color: #fff;
    }
  }
}

.select_option{
  color: #02a6b5 !important;
}

</style>
